
import { createStore as reduxCreateStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from 'redux-thunk';

import displayChangesReducer from '../reducers/displayChanges';
import valueStorageReducer from '../reducers/valueStorage';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers = typeof window !== `undefined` && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createStore = () => reduxCreateStore(
    combineReducers({
        displayChanges: displayChangesReducer,
        valueStorage: valueStorageReducer
    }), 
    composeEnhancers(applyMiddleware(thunk))
)

export default createStore