export default {
  colors: {
    background: '#F9F4EF',
    white: '#fff',
    text: '#08101F',
    // primary: '#1D3557',
    primary: '#423E3D',
    // secondary: '#A8DADC',
    secondary: '#A03232',
    primaryLight: '#423E3D',
    highlight: '#E6C073',
  },
  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    navLinks: 'Roboto, sans-serif',
    copy: 'Roboto, serif',
    title: 'Bitter, sans-serif',
  },
  sizes: {
    maxWidth: '1300px',
    maxWidthCentered: '850px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  effects: {
    transition: 'all 0.3s ease-in',
    boxShadow: '0px 2.5px 5px 0px rgba(0,0,0,0.75)',
  },
  outlines: {
    borders: '1px solid #c6c3c3',
    tileSectionBorderBottom: `
      content: "";
      position: absolute;
      border-bottom: 1px solid #c6c3c3;
      right: 0;
      bottom: -32px;
      left: 0;
      top: auto;
    `,
    tileSectionBorderTop: `
      content: "";
      position: absolute;
      border-bottom: 1px solid #c6c3c3;
      right: 0;
      top: -15px;
      left: 0;
      bottom: auto;
    `,
    tileSectionBorderRight: `
      content: "";
      position: absolute;
      border-right: 1px solid #c6c3c3;
      right: -15px;
      bottom: 0;
      left: auto;
      top: 0;
    `,
    tileSectionBorderLeft: `
      content: "";
      position: absolute;
      border-left: 1px solid #c6c3c3;
      left: -15px;
      bottom: 0;
      right: auto;
      top: 0;
    `
  }
}
