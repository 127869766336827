const displayChangesDefaultState = [
    {
        showMobileMenu: false,
        showMobileMenuButton: false,
        showIntroCTA: true,
        numberOfExpandedFAQ: 0,
    }
];

const displayChangesReducer = (state = displayChangesDefaultState, action) => {
    switch (action.type) {
        case 'SHOW_MOBILE_MENU':
            return state.map(property => {
                return {
                    ...property,
                    showMobileMenu: true,
                }
            });
        case 'HIDE_MOBILE_MENU':
            return state.map(property => {
                return {
                    ...property,
                    showMobileMenu: false,
                }
            })
        case 'SHOW_MOBILE_MENU_BUTTON':
            return state.map(property => {
                return {
                    ...property,
                    showMobileMenuButton:true,
                }
            })
        case 'HIDE_MOBILE_MENU_BUTTON':
            return state.map(property => {
                return {
                    ...property,
                    showMobileMenuButton: false,
                }
            })
        case 'HIDE_INTRO_CTA':
            return state.map(property => {
                return {
                    ...property,
                    showIntroCTA: false,
                }
            })
        case 'INCREMENT_EXPANDED_FAQ':
            return state.map(property => {
                return {
                    ...property,
                    numberOfExpandedFAQ: property.numberOfExpandedFAQ + 1,
                }
            })
        case 'DECREMENT_EXPANDED_FAQ':
            return state.map(property => {
                return {
                    ...property,
                    numberOfExpandedFAQ: property.numberOfExpandedFAQ - 1,
                }
            })
        case 'RESET_EXPANDED_FAQ':
            return state.map(property => {
                return {
                    ...property,
                    numberOfExpandedFAQ: 0,
                }
            })
        case 'SET_ALL_EXPANDED_FAQ':
            return state.map(property => {
                return {
                    ...property,
                    numberOfExpandedFAQ: action.property
                }
            })
        default:
            return state;
    }
}

export default displayChangesReducer;