const valueStorageDefaultState = [
    {
        mobileMenuDisplay: false,
        searchValue: "",
    }
];

const valueStorageReducer = (state = valueStorageDefaultState, action) => {
    switch (action.type) {
        case 'DISPLAY_MOBILE_MENU':
            return state.map(property => {
                return {
                    ...property,
                    mobileMenuDisplay: true,
                }
            });
        case 'UPDATE_SEARCH_VALUE':
            return state.map(property => {
                return {
                    ...property,
                    searchValue: action.searchValue,
                }
            })
        default:
            return state;
    }
}

export default valueStorageReducer;